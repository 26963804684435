<template>
  <div class="locale-changer mx-1 my-0">
    <b-form-select
      v-model="$i18n.locale"
      :options="langs"
      size="sm"
      @change="changeLanguage()"
    >
    </b-form-select>
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';

export default {
  name: 'SelectLanguage',
  components: {
    BFormSelect,
  },
  data() {
    return {
      langs: [
        { value: 'en', text: 'ENG' },
        { value: 'ru', text: 'RUS' },
        { value: 'he', text: 'HEB' },
      ],
    };
  },
  mounted() {
    const lang = window.localStorage.getItem('language');
    this.$i18n.locale = 'en' || lang;

    this.changeLanguage();
  },
  methods: {
    changeLanguage() {
      this.$store.dispatch('UPDATE_LANGUAGE', this.$i18n.locale);
      window.localStorage.setItem('language', this.$i18n.locale);
    },
  },
};
</script>
<style>
.locale-changer .custom-select option {
  margin: 10px 0;
}
</style>
